import {
  ColumnDefinitionInputV1,
  ColumnRuleAncestorInputV1,
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleConcatInputV1,
  ColumnRuleConstantInputV1,
  ColumnRuleDescendantInputV1,
  ColumnRuleEventPropertyInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleGetItemPropertyInputV1,
  ColumnRuleInputV1,
  ColumnRuleItemSearchInputV1,
  ColumnRulePathInputV1,
  ColumnRulePathSearchInputV1,
  ColumnRuleScalarCreatorInputV1,
  ColumnRuleSetItemPropertyInputV1,
  ColumnRuleTextExtractorInputV1,
  ColumnRuleTextReplacementInputV1,
  ColumnRuleTreePathCreatorInputV1,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { PropertyMatchOperatorEnum } from '@/sdk/model/ColumnRuleDescendantInputV1';
import {
  DecoratedScalingTableColumnDefinition,
  PotentialColumnDefinition,
  ScalingTableColumnDefinition,
} from '@/tableDefinitionEditor/tableDefinition.types';
import {
  ColumnRuleWithMetadata,
  ParameterWithMetadata,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';

export type ColumnRuleInputParameters =
  | ColumnRuleGetItemPropertyInputV1
  | ColumnRulePathInputV1
  | ColumnRuleAncestorInputV1
  | ColumnRuleConcatInputV1
  | ColumnRuleDescendantInputV1
  | ColumnRuleEventPropertyInputV1
  | ColumnRuleFormulaCreatorInputV1
  | ColumnRuleAssetCreatorInputV1
  | ColumnRuleTreePathCreatorInputV1
  | ColumnRuleConstantInputV1
  | ColumnRuleSetItemPropertyInputV1
  | ColumnRulePathSearchInputV1
  | ColumnRuleTextExtractorInputV1
  | ColumnRuleTextReplacementInputV1
  | ColumnRuleScalarCreatorInputV1;

export type CombinedColumnRuleInputParameters = ColumnRuleGetItemPropertyInputV1 &
  ColumnRulePathInputV1 &
  ColumnRuleAncestorInputV1 &
  ColumnRuleConcatInputV1 &
  ColumnRuleDescendantInputV1 &
  ColumnRuleEventPropertyInputV1 &
  ColumnRuleFormulaCreatorInputV1 &
  ColumnRuleAssetCreatorInputV1 &
  ColumnRuleTreePathCreatorInputV1 &
  ColumnRuleConstantInputV1 &
  ColumnRuleSetItemPropertyInputV1 &
  ColumnRulePathSearchInputV1 &
  ColumnRuleTextExtractorInputV1 &
  ColumnRuleTextReplacementInputV1 &
  ColumnRuleItemSearchInputV1 &
  ColumnRuleScalarCreatorInputV1;

export interface ColumnRuleInput extends ColumnRuleInputV1 {
  AssetTreeCreator?: ColumnRuleTreePathCreatorInputV1;
  AssetTreeAddChild?: ColumnRuleTreePathCreatorInputV1;
}

export type ColumnRule = keyof ColumnRuleInput;
export type RuleParameter = keyof CombinedColumnRuleInputParameters;

// TODO CRAB-41437 unrestrict these permissions options
export const restrictedParams: string[] = [
  SeeqNames.MaterializedTables.Parameters.IdentityId,
  SeeqNames.MaterializedTables.Parameters.PermissionString,
];

export const ItemTypeOptions = [
  { value: SeeqNames.Types.Condition, label: 'ITEM_FINDER.TYPES_CONDITION' },
  { value: SeeqNames.Types.Signal, label: 'ITEM_FINDER.TYPES_SIGNAL' },
  { value: SeeqNames.Types.Scalar, label: 'ITEM_FINDER.TYPES_SCALAR' },
  { value: SeeqNames.Types.Asset, label: 'ITEM_FINDER.TYPES_ASSET' },
];

export const PropertyNameOptions = [
  { value: SeeqNames.Properties.Name, label: 'ITEM_FINDER.PROPERTY_SELECT_NAME' },
  { value: SeeqNames.Properties.Description, label: 'ITEM_FINDER.PROPERTY_SELECT_DESCRIPTION' },
  { value: SeeqNames.Properties.DatasourceId, label: 'ITEM_FINDER.PROPERTY_SELECT_DATASOURCE_ID' },
  { value: SeeqNames.Properties.DatasourceName, label: 'ITEM_SEARCH.PROPERTY.DATASOURCE_NAME' },
];

export const PropertyMatchOperatorEnumToLabel: Record<PropertyMatchOperatorEnum, string> = {
  [PropertyMatchOperatorEnum.EQUALS]: 'Equals',
  [PropertyMatchOperatorEnum.EQUALSIGNORECASE]: 'Equals Ignore Case',
  [PropertyMatchOperatorEnum.NOTEQUALS]: 'Not Equals',
  [PropertyMatchOperatorEnum.WITHIN]: 'Within',
  [PropertyMatchOperatorEnum.STRINGCONTAINS]: 'String Contains',
};

export const ColumnTypeOptions = [
  {
    value: ColumnTypeEnum.TEXT,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.TEXT.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.TEXT.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.NUMERIC,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.NUMERIC.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.NUMERIC.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.UUID,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.UUID.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.UUID.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.TIMESTAMPTZ,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.TIMESTAMPTZ.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.TIMESTAMPTZ.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.BOOLEAN,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.BOOLEAN.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.BOOLEAN.DESCRIPTION',
  },
] as const;

export const AllColumnEnumOptions = Object.values(ColumnTypeEnum) as ColumnTypeEnum[];

export interface BaseInputParametersProps {
  setParameter: (parameter: RuleParameter, value: any) => void;
  testId: string;
  ruleParameterWithMetadata: ParameterWithMetadata;
  currentValue?: any;
  extraClassNames?: string;
  ruleType: ColumnRuleWithMetadata;
  columnType?: ColumnTypeEnum;
  showError: boolean;
}

export interface TextInputParameterProps extends BaseInputParametersProps {
  placeholderOverride?: string;
  readonly?: boolean;
  extraClassNames?: string;
}

export interface BooleanInputProps extends BaseInputParametersProps {
  disabled?: boolean;
}

export interface ColumnDropdownInputProps extends BaseInputParametersProps {
  removeParameter: (parameter: RuleParameter) => void;
  allColumnDefinitions: DecoratedScalingTableColumnDefinition[];
  currentColumnDefinition?: ColumnDefinitionInputV1;
  potentialColumnsNotYetCreated?: PotentialColumnDefinition[];
  firstIsCreatable?: boolean;
  onRenameFirstColumn?: (newName: string) => void;
  firstColumnIsRestricted?: boolean;
  otherColumnsAreRestricted?: boolean;
  defaultDropdownDescription?: string;
  hidePotentialColumns?: boolean;
}

export interface ConfigureTreeCreationOrUpdatePseudoRulesProps {
  allColumnDefinitions: ScalingTableColumnDefinition[];
  currentRuleState: Partial<CombinedColumnRuleInputParameters>;
  setParameter: (parameter: RuleParameter, value: any, skipUpdateColumnDefToCreate: boolean) => void;
  removeParameter: (parameter: RuleParameter) => void;
  currentColumnDefinition?: ColumnDefinitionInputV1;
  columnRuleToEdit?: ColumnRuleTreePathCreatorInputV1;
  columnType?: ColumnTypeEnum;
  getPotentialColumns: (
    columnDefinitionsToCreate: PotentialColumnDefinition[],
    potentialRootColumnPairIfNotAlreadySelectedForCreation?: {
      rootText: PotentialColumnDefinition;
      rootAsset: PotentialColumnDefinition;
    },
  ) => PotentialColumnDefinition[];
  setPotentialAdditionalColumns: React.Dispatch<React.SetStateAction<PotentialColumnDefinition[]>>;
  potentialAdditionalColumns: PotentialColumnDefinition[];
  fetchDecoratedColumns: (
    fetchAllowedColumnsForFirstInput: (
      columns: DecoratedScalingTableColumnDefinition[],
    ) => { columnName: string; description: string }[],
  ) => Promise<DecoratedScalingTableColumnDefinition[]>;
  setDecoratedColumnDefinitions: React.Dispatch<React.SetStateAction<DecoratedScalingTableColumnDefinition[]>>;
  decoratedColumnDefinitions: DecoratedScalingTableColumnDefinition[];
  setColumnDefinitionsToCreate: React.Dispatch<React.SetStateAction<PotentialColumnDefinition[]>>;
  columnDefinitionsToCreate: PotentialColumnDefinition[];
  showError: boolean;
}

export interface PropertyInputProps {
  currentPropertyName?: string;
  currentPropertyOperator?: string;
  currentPropertyValue?: string;
  propertyNameParameter: ParameterWithMetadata;
  propertyOperatorParameter: ParameterWithMetadata;
  propertyValueParameter: ParameterWithMetadata;
  setParameter: (parameter: RuleParameter, value: any) => void;
  isBeingEdited?: boolean;
  removeParameter: (parameter: RuleParameter) => void;
  ruleWithMetadata: ColumnRuleWithMetadata;
  allColumnDefinitions: ScalingTableColumnDefinition[];
  currentColumnDefinition?: ColumnDefinitionInputV1;
  showError: boolean;
}

export interface CombinedInputProps extends TextInputParameterProps, ColumnDropdownInputProps, BooleanInputProps {}

export interface ColumnRuleInputWithUIMetadata extends ColumnRuleInputV1 {
  isCollapsed?: boolean;
}
